import { t } from '@lingui/macro';
import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import FlashMessage from 'Components/FlashMessage/FlashMessage';
import Footer from 'Components/Footer/Footer';
import InstallAppBanner from 'Components/InstallAppBanner/InstallAppBanner';
import ScrollToTop from 'Components/ScrollToTop/ScrollToTop';
import ReduceServices from 'Components/Settings/ReduceServices/ReduceServices.async';
import Auth0Callback from 'common/Auth0/AuthorizationCodeFlowCallback.async';
import Routes from 'common/CustomRoutes/Routes';
import Header from 'common/Header/Header';
import { useQueryParams } from 'common/hooks';
import favicon32 from 'common/icons/favicon/favicon-32.png';
import favicon72 from 'common/icons/favicon/favicon-72.png';
import favicon120 from 'common/icons/favicon/favicon-120.png';
import favicon144 from 'common/icons/favicon/favicon-144.png';
import favicon152 from 'common/icons/favicon/favicon-152.png';
import favicon195 from 'common/icons/favicon/favicon-195.png';
import favicon from 'common/icons/favicon/favicon.ico';
import fbCover from 'common/icons/fb-cover.png';
import config from 'config/config';
import AdminMessage from 'pages/AdminMessage/AdminMessage.async';
import CouponsPage from 'pages/CouponsPage/CouponsPage';
import OlxSPOnboarding from 'pages/OlxSPOnboarding/OlxSPOnboarding.async';
import PayWithFixly from 'pages/PayWithFixly/PayWithFixly.async';
import Payments from 'pages/Payments/Payments.async';
import useSnackbar from 'uikit/Snackbar/useSnackbar';
import routes from 'utils/routeTranslator';
import { SalesforceChat } from 'utils/salesforce';

import { useCurrentUser } from '../CurrentUser/CurrentUser';
import EndpointSwitcher from './EndpointSwitcher/EndpointSwitcher';
import OrganizationSchema from './OrganizationSchema';

const Entry: FC = () => {
  const siteTitle = config.SITE_NAME;
  // eslint-disable-next-line max-len
  const siteDescription = t`Innowacyjny serwis ułatwiający zlecenie usług w kategoriach: budowa i remont, hydraulik, elektryk, meble. Wykonawcy w jednym miejscu. Fixly od OLX!`;
  const ogTitlePrefix = t`Budowa domu, remont mieszkania, meble na wymiar`;
  const { currentUser } = useCurrentUser();
  const { showSnackbar } = useSnackbar();
  const queryParams = useQueryParams();
  const errorMessage = queryParams.get('errorMessage');

  useEffect(() => {
    if (errorMessage) {
      showSnackbar({
        message: errorMessage,
        variant: 'error',
      });
    }
  }, [errorMessage, showSnackbar]);

  return (
    <>
      <Helmet>
        <title>{siteTitle}</title>
        <meta property="og:title" content={`${ogTitlePrefix} ${siteTitle}`} />
        <meta property="og:description" content={siteDescription} />
        <meta name="description" content={siteDescription} />
        <meta property="og:site_name" content={siteTitle} />
        <meta name="application-name" content={siteTitle} />
        <meta property="og:image" content={fbCover} />
        <link rel="shortcut icon" href={favicon} type="image/x-icon" />
        <link rel="apple-touch-icon" href={favicon152} />
        <link rel="icon" href={favicon32} sizes="32x32" />
        <link rel="shortcut icon" sizes="196x196" href={favicon195} />
        <link rel="apple-touch-icon" sizes="192x192" href={favicon195} />
        <link rel="apple-touch-icon" sizes="152x152" href={favicon152} />
        <link rel="apple-touch-icon" sizes="144x144" href={favicon144} />
        <link rel="apple-touch-icon" sizes="120x120" href={favicon120} />
        <link rel="apple-touch-icon" sizes="72x72" href={favicon72} />
      </Helmet>

      <Switch>
        <Route path={routes.get('pwf::index')} component={PayWithFixly} />
        <Route path={routes.get('payments::index')} component={Payments} />
        <Route path={routes.get('provider::settings.getReduceServices')} component={ReduceServices} exact />
        <Route path={routes.get('adminMessage::index')} component={AdminMessage} />
        <Route path={routes.get('front::coupons.direct')} component={CouponsPage} exact />
        <Route path={routes.get('front::provider.register.olx')} component={OlxSPOnboarding} exact />
        <Route path={routes.get('front::olx.authCallback')} component={Auth0Callback} exact />

        <Route
          render={(routeProps) => (
            <>
              <Header />
              <OrganizationSchema />
              <div id="content">
                <Routes />
              </div>
              <Footer />
              <div className="stickers">
                {currentUser?.isProvider && routeProps.location.pathname === routes.get('front::dashboard') && (
                  <InstallAppBanner type="global" touchPoint="available_requests" />
                )}
              </div>
            </>
          )}
        />
      </Switch>
      <FlashMessage />
      <ScrollToTop />
      <SalesforceChat />

      {config.SHOW_ENDPOINT_SWITCHER && <EndpointSwitcher />}
    </>
  );
};

export default Entry;
